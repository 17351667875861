import "/third_party/vicowa-web-components/dist/vicowa-sidebar/vicowa-sidebar.js";
import "/third_party/vicowa-web-components/dist/vicowa-button/vicowa-button.js";
import "/third_party/vicowa-web-components/dist/vicowa-string/vicowa-string.js";
import "/third_party/vicowa-web-components/dist/vicowa-icon/vicowa-icon.js";
import "/third_party/vicowa-web-components/dist/vicowa-image-container/vicowa-image-container.js";
import "/third_party/vicowa-web-components/dist/vicowa-content-container/vicowa-content-container.js";
import "/webcomponents/davinci-icons/davinci-icons.js";
import "/webcomponents/davinci-navigate/davinci-navigate.js";
import "/webcomponents/davinci-navigate-control/davinci-navigate-control.js";
import translate from "/third_party/vicowa-web-components/dist/utilities/translate.js";
import { createQuickAccess } from "/third_party/web-component-base-class/dist/tools.js";
import { getRouter } from "/third_party/vicowa-web-components/dist/utilities/route.js";

const router = getRouter(window);
const controls = createQuickAccess(document, "id");
translate.addTranslationLocation("/resources/i18n");
translate.language = (document.cookie.split(";").map((p_Pair) => p_Pair.trim().split("=")).filter((p_Pair) => p_Pair[0] === "language")[0] || [])[1] || "en";

controls.navigateControl.router = router;

controls.content.addChangeListener(({ control }) => {
	controls.titleContainer.string = control.pageTitle;
});

translate.addTranslationUpdatedObserver((p_Handler) => {
	document.querySelector("html").setAttribute("lang", p_Handler.language);
	document.cookie = `language=${p_Handler.language};path=/`;
}, window);
controls.english.addEventListener("click", () => { translate.language = "en"; });
controls.chinese.addEventListener("click", () => { translate.language = "zh"; });
controls.logo.addEventListener("click", () => {
	controls.content.pageTitle = "da Vinci - Home";
	controls.content.location = "home-content.js";
});

const mediaChange = window.matchMedia('(max-width: 600px)');
setTimeout(() => {
	const checkMedia = (p_Result) => {
		controls.sidebar.expanded = !p_Result.matches;
	};
	checkMedia(mediaChange);
	mediaChange.addListener(checkMedia);
}, 1000);

controls.toggleExpand.addEventListener('click', (p_Event) => {
	controls.sidebar.expanded = !controls.sidebar.expanded;
	const handleOutsideClick = (p_ClickEvent) => {
		const viewRect = controls.navigationArea.getBoundingClientRect();
		if (p_ClickEvent.clientX > viewRect.right || p_ClickEvent.clientX < viewRect.left || p_ClickEvent.clientY > viewRect.bottom || p_ClickEvent.clientY < viewRect.top) {
			controls.sidebar.expanded = false;
			window.removeEventListener('click', handleOutsideClick);
		}
	};
	if (controls.sidebar.expanded) {
		window.addEventListener('click', handleOutsideClick);
	}
	p_Event.cancelBubble = true;
});

router.addRoute('/:file(.*)', (context, next) => {
	const parts = context.params.file.split('#');
	controls.content.location = `./${parts[0] || 'home-content'}.js`;
	context.element = parts[0].split('/').pop();
	context.anchor = parts[1];
	next();
}, controls.navigateControl.onRoute, (context) => {
	if (context.anchor && controls.content.$$(context.element)) {
		controls.content.$$(context.element).$$(`#${context.anchor}`).scrollIntoView();
	}
});
